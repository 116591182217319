import { useEffect, useState } from 'react'

const useClientSideParam = (paramName: string, fallback: any = null) => {
  const [parameter, setParameter] = useState<string | null>(fallback)
  useEffect(() => {
    if (typeof window === 'undefined') {
      return
    }
    const search = new URLSearchParams(window.location.search)
    const value = search.get(paramName)
    if (value) {
      setParameter(value)
    }
  }, [paramName])

  return parameter
}

export default useClientSideParam
