import LifetimeQuotesIcon from '@/components/icons/LifetimeQuotesIcon'
import PageContainer from '@/components/PageContainer'
import exlinks from '@/exlinks'
import useClientSideParam from '@/hooks/useClientSideParams'
import useLocalStorage from '@/hooks/useLocalStorage'
import useSignInReducer, {
  Action as SignInAction,
  SignInReducer,
  SigninStep,
} from '@/hooks/useSignInReducer'
import { useXplanSyncErrors } from '@/hooks/useXplanSyncError'
import useSignInMutation from '@/mutations/useSignInMutation'
import useSignInWithCodeMutation from '@/mutations/useSignInWithCodeMutation'
import routes from '@/routes'
import { ClientInputs, StyledForm } from '@mylifetime-packages/form-components'
import {
  Button,
  Center,
  ChakraButton,
  Flex,
  Link,
  Text,
  useToast,
  VStack,
} from '@packages/ui-components'
import addMinutes from 'date-fns/addMinutes'
import { Formik, FormikHelpers, FormikProps } from 'formik'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import * as Yup from 'yup'

const LoginSchema = Yup.object()
  .shape({
    email: Yup.string().email('Invalid email').required('Required'),
  })
  .defined()

const CodeSchema = Yup.object()
  .shape({
    code: Yup.string().required('Required'),
  })
  .defined()

interface EmailFormValues {
  email: string
}

interface CodeFormValues {
  code: string | undefined
}

interface EmailLoginFormProps {
  onSubmit: (values: EmailFormValues, formikBag: FormikHelpers<EmailFormValues>) => void
  submitting: boolean
  initialValues: EmailFormValues
}

interface CodeFormProps {
  onSubmit: (values: CodeFormValues, formikBag: FormikHelpers<CodeFormValues>) => void
  submitting: boolean
  initialValues: CodeFormValues
  email: string
  onChangeEmail: () => void
  showInvalidCodeText: boolean
}

const EMAIL_LOCALSTORAGE_KEY = 'signinEmail'
const EXPIRY_MINUTES = 15

const expiryISODate = () => addMinutes(new Date(), EXPIRY_MINUTES).toISOString()

type EmailSectionProps = { initialEmail: string; signInReducer: SignInReducer }
const EmailSection = ({ initialEmail, signInReducer }: EmailSectionProps) => {
  const [mutate, { loading }] = useSignInMutation()

  const [, setStoredEmail] = useLocalStorage(EMAIL_LOCALSTORAGE_KEY, '{}')

  const { dispatch } = signInReducer

  const onSubmit = async (values: EmailFormValues, formikBag: FormikHelpers<EmailFormValues>) => {
    const { email } = values
    try {
      await mutate({
        variables: {
          input: { email },
        },
        onCompleted(data) {
          if (data && data.sendSignInEmail.__typename === 'SendSignInEmailSuccessPayload') {
            setStoredEmail(JSON.stringify({ email, expiry: expiryISODate() }))
            dispatch({ type: SignInAction.SetEmail, payload: { email } })
          } else {
            if (data && data.sendSignInEmail.__typename === 'SendSignInEmailErrorPayload') {
              const errorMessage = data.sendSignInEmail.error.message

              if (errorMessage.includes('app access disabled')) {
                formikBag.setFieldError(
                  'email',
                  "You don't seem to be authorised to access MyLifetime. Please contact the client care centre or your adviser."
                )
              }
              if (errorMessage.includes('This account has been deactivated')) {
                formikBag.setFieldError(
                  'email',
                  'This account has been deactivated. Please contact Lifetime to request access.'
                )
              } else {
                formikBag.setFieldError('email', 'Email not found')
              }
            } else {
              formikBag.setFieldError('email', 'There was an error during sign-in')
            }
          }
        },
        onError() {
          dispatch({
            type: SignInAction.SetError,
            payload: { error: 'Failed to sign-in' },
          })
        },
      })
    } catch (e) {
      dispatch({
        type: SignInAction.SetError,
        payload: { error: 'Something went wrong. Please try again.' },
      })
    }
  }

  return (
    <EmailForm onSubmit={onSubmit} submitting={loading} initialValues={{ email: initialEmail }} />
  )
}

const EmailForm = ({ onSubmit, submitting, initialValues }: EmailLoginFormProps) => {
  return (
    <>
      <Text fontSize="md" color="grey.900" mb="4">
        Enter your email below to login to MyLifetime.
      </Text>
      <Formik initialValues={initialValues} validationSchema={LoginSchema} onSubmit={onSubmit}>
        {(props: FormikProps<EmailFormValues>) => (
          <StyledForm>
            <ClientInputs.Email />
            <Button isFullWidth type="submit" isLoading={props.isSubmitting || submitting}>
              Login
            </Button>
          </StyledForm>
        )}
      </Formik>
    </>
  )
}

type CodeSectionProps = { signInReducer: SignInReducer; onChangeEmail: () => void }
const CodeSection = ({ signInReducer, onChangeEmail }: CodeSectionProps) => {
  const [mutate, { loading }] = useSignInWithCodeMutation()
  const { setXplanSyncErrors, deleteXplanSyncErrors } = useXplanSyncErrors()

  const [storedEmailJSON, , deleteStoredEmail] = useLocalStorage(EMAIL_LOCALSTORAGE_KEY, '{}')
  const storedEmail = JSON.parse(storedEmailJSON)

  const { state, dispatch } = signInReducer

  const onSubmit = async (values: CodeFormValues) => {
    const { code } = values
    try {
      if (!code) {
        dispatch({
          type: SignInAction.SetError,
          payload: { error: 'Please provide a code for sign-in' },
        })
        return
      }

      await mutate({
        variables: {
          input: { email: storedEmail.email, code: code || '' },
        },
        onCompleted(data) {
          if (data && data.signIn.__typename === 'SignInSuccessPayload') {
            if (data.signIn.xplanSyncErrors) {
              setXplanSyncErrors([...data.signIn.xplanSyncErrors])
            } else {
              deleteXplanSyncErrors()
            }
            deleteStoredEmail()
            dispatch({ type: SignInAction.SetSuccess })
          } else {
            dispatch({ type: SignInAction.SetInvalidOTP })
          }
        },
        onError() {
          dispatch({
            type: SignInAction.SetError,
            payload: { error: 'Failed to sign-in with code provided' },
          })
        },
      })
    } catch (e) {
      dispatch({
        type: SignInAction.SetError,
        payload: { error: 'Something went wrong. Please Try Again.' },
      })
    }
  }

  return (
    <CodeForm
      onSubmit={onSubmit}
      submitting={loading}
      initialValues={{ code: undefined }}
      email={storedEmail.email}
      onChangeEmail={onChangeEmail}
      showInvalidCodeText={state.invalidOTP || false}
    />
  )
}

const CodeForm = ({
  onSubmit,
  submitting,
  initialValues,
  email,
  onChangeEmail,
  showInvalidCodeText,
}: CodeFormProps) => {
  return (
    <>
      <Text fontSize="md" color="grey.900" mb="4">
        We&apos;ve sent an email containing your unique timed login code. Please enter it below to
        login.
      </Text>
      <Formik initialValues={initialValues} validationSchema={CodeSchema} onSubmit={onSubmit}>
        {(props: FormikProps<CodeFormValues>) => (
          <StyledForm>
            <ClientInputs.OTP />

            {showInvalidCodeText && (
              <Text align="center" size="xs">
                Invalid code entered. Please try entering the code again or{' '}
                <Link style={{ textDecoration: 'underline' }} onClick={onChangeEmail}>
                  request a new code
                </Link>
                .
              </Text>
            )}

            <Button isFullWidth type="submit" isLoading={props.isSubmitting || submitting}>
              Login
            </Button>

            <Flex justify="center" align="center">
              <Text fontSize="xs" color="gray" mr="4">
                Signing in as: {email}
              </Text>

              <ChakraButton
                wordBreak={'break-word'}
                whiteSpace={'normal'}
                size="xs"
                color="blue.700"
                variant="link"
                onClick={onChangeEmail}
              >
                Click to change or request new code.
              </ChakraButton>
            </Flex>
          </StyledForm>
        )}
      </Formik>
    </>
  )
}

const SignInForm = ({ initialEmail }: { initialEmail: string }) => {
  const toast = useToast()
  const router = useRouter()
  const signInReducer = useSignInReducer()

  const { state, dispatch } = signInReducer

  const [storedEmailJSON, , deleteStoredEmail] = useLocalStorage(EMAIL_LOCALSTORAGE_KEY, '{}')
  const storedEmail = JSON.parse(storedEmailJSON)

  const storedEmailStillValid = new Date() < new Date(storedEmail.expiry)

  const onChangeEmail = () => {
    deleteStoredEmail()
    dispatch({ type: SignInAction.Reset })
  }

  useEffect(() => {
    if (state.error) {
      toast.closeAll()
      toast({
        title: state.error,
        description: `If the error continues, please contact client care: ${exlinks.clientcare}`,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.error])

  useEffect(() => {
    switch (state.currentPage) {
      case SigninStep.Email: {
        if (storedEmail.email && storedEmailStillValid) {
          dispatch({ type: SignInAction.SetEmail, payload: { email: storedEmail.email } })
        }
        break
      }

      case SigninStep.Code: {
        if (storedEmail.email && !storedEmailStillValid) {
          deleteStoredEmail()
          dispatch({ type: SignInAction.Reset })
        }
        break
      }

      case SigninStep.Success: {
        router.push(routes.dashboard)
        break
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.currentPage])

  if (state.currentPage === SigninStep.Email) {
    return <EmailSection initialEmail={initialEmail} signInReducer={signInReducer} />
  }

  if (state.currentPage === SigninStep.Code) {
    return <CodeSection signInReducer={signInReducer} onChangeEmail={onChangeEmail} />
  }

  return <Text align="center">Signing in...</Text>
}

const Login = () => {
  let initialEmail = useClientSideParam('email', '') as string
  initialEmail =
    typeof initialEmail === 'string' ? initialEmail.replace(/\u200B/g, '') : initialEmail

  return (
    <PageContainer
      title="Login to MyLifetime"
      showNav={false}
      key={initialEmail} // hack to trigger re-render
      justifyContent="space-between"
    >
      <VStack spacing={4} align="stretch" width="100%">
        <Center py={8}>
          <LifetimeQuotesIcon w={104} h={104} />
        </Center>
        <SignInForm initialEmail={initialEmail} />
      </VStack>
      <Flex justifyContent="center">
        <Text fontSize="xs" color="gray">
          Client Care Centre:
        </Text>
        &nbsp;
        <Link
          href={`mailto:${exlinks.clientcare}`}
          color="gray"
          aria-label={'mailto to' + exlinks.clientcare}
          fontSize="xs"
        >
          {exlinks.clientcare}
        </Link>
      </Flex>
    </PageContainer>
  )
}
export default Login
