/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type SignInInput = {
    code: string;
    email: string;
};
export type useSignInWithCodeMutationVariables = {
    input: SignInInput;
};
export type useSignInWithCodeMutationResponse = {
    readonly signIn: {
        readonly __typename: "SignInSuccessPayload";
        readonly success: boolean;
        readonly xplanSyncErrors: ReadonlyArray<{
            readonly module: string;
            readonly error: string;
        }> | null;
    } | {
        readonly __typename: "SignInErrorPayload";
        readonly error: {
            readonly message: string;
        };
    } | {
        readonly __typename: "InvalidGrantError";
        readonly reason: string;
    } | {
        /*This will never be '%other', but we need some
        value in case none of the concrete values match.*/
        readonly __typename: "%other";
    };
};
export type useSignInWithCodeMutation = {
    readonly response: useSignInWithCodeMutationResponse;
    readonly variables: useSignInWithCodeMutationVariables;
};



/*
mutation useSignInWithCodeMutation(
  $input: SignInInput!
) {
  signIn(input: $input) {
    __typename
    ... on SignInSuccessPayload {
      success
      xplanSyncErrors {
        module
        error
      }
    }
    ... on SignInErrorPayload {
      error {
        message
      }
    }
    ... on InvalidGrantError {
      reason
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "signIn",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "success",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "XplanSyncError",
            "kind": "LinkedField",
            "name": "xplanSyncErrors",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "module",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "error",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "type": "SignInSuccessPayload",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "type": "SignInErrorPayload",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "reason",
            "storageKey": null
          }
        ],
        "type": "InvalidGrantError",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useSignInWithCodeMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useSignInWithCodeMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e57c35ff8c7d3919209cf9d9a94acdf1",
    "id": null,
    "metadata": {},
    "name": "useSignInWithCodeMutation",
    "operationKind": "mutation",
    "text": "mutation useSignInWithCodeMutation(\n  $input: SignInInput!\n) {\n  signIn(input: $input) {\n    __typename\n    ... on SignInSuccessPayload {\n      success\n      xplanSyncErrors {\n        module\n        error\n      }\n    }\n    ... on SignInErrorPayload {\n      error {\n        message\n      }\n    }\n    ... on InvalidGrantError {\n      reason\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'f02e5c76b6b05364f78903194c486ef5';
export default node;
