import { graphql } from 'react-relay'
import { useMutation } from 'relay-hooks'

import { useSignInMutation } from '@/__generated__/useSignInMutation.graphql'
import { GraphQLTaggedNode } from 'relay-runtime'
const useSignInMutationGQL = graphql`
  mutation useSignInMutation($input: SendSignInEmailInput!) {
    sendSignInEmail(input: $input) {
      __typename
      ... on SendSignInEmailSuccessPayload {
        __typename
        success
      }
      ... on SendSignInEmailErrorPayload {
        __typename
        error {
          message
        }
      }
    }
  }
`
const useSignInMutationHook = () => {
  return useMutation<useSignInMutation>(useSignInMutationGQL as GraphQLTaggedNode)
}
export default useSignInMutationHook
