import { Icon, IconProps, useToken } from '@chakra-ui/react'

const LifetimeQuotesIcon = (props: IconProps) => {
  const [blue400, blue800] = useToken('colors', ['blue.400', 'blue.800'])
  return (
    <Icon viewBox="0 0 256 256" color={blue800} {...props}>
      <path
        fill={blue400}
        d="M24 30v70c0 35 8 63 25 82 16 19 39 32 64 38v-25l-1-1v-1a77 77 0 01-48-42c-5-10-7-21-6-33l1-5h54s2 0 2-4V82c0-39-23-52-45-52H24zM203 30c-23 0-46 14-46 54v28l1 4h56l1 6c1 11-1 23-7 34a80 80 0 01-48 43l-2 2v26c26-6 49-19 67-39 17-21 26-49 26-86V30h-48z"
      />
      <path
        fill="currentColor"
        d="M61 26c7 0 20 2 31 12s16 24 16 43v29l-1 4H48v13a81 81 0 0056 72h1l1 2v26c-29-7-51-21-67-40-18-20-27-50-27-86V26h49zm0-12H0v87c0 39 10 71 29 94 20 23 47 38 82 45l7 2v-42a15 15 0 00-9-13 68 68 0 01-49-60h46c9 0 14-7 14-17V81c0-49-32-67-59-67zM244 26v75c0 36-9 66-27 86-16 19-38 33-67 40v-26a2 2 0 011-2 80 80 0 0057-72v-12h-58l-1-1-1-4V81c0-19 5-34 16-43 11-10 24-12 31-12h49zm12-12h-61c-27 0-59 18-59 67v29c0 10 6 17 14 17h46a69 69 0 01-48 61 14 14 0 00-10 13v41l7-2c35-7 62-22 82-45 19-23 29-55 29-94V14z"
      />
    </Icon>
  )
}
export default LifetimeQuotesIcon
