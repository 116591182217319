import { graphql } from 'react-relay'
import { useMutation } from 'relay-hooks'

import { useSignInWithCodeMutation as useSignInWithCodeMutationType } from '@/__generated__/useSignInWithCodeMutation.graphql'
import { GraphQLTaggedNode } from 'relay-runtime'

const useSignInCodeGQL = graphql`
  mutation useSignInWithCodeMutation($input: SignInInput!) {
    signIn(input: $input) {
      __typename
      ... on SignInSuccessPayload {
        success
        xplanSyncErrors {
          module
          error
        }
      }
      ... on SignInErrorPayload {
        error {
          message
        }
      }
      ... on InvalidGrantError {
        reason
      }
    }
  }
`
const useSignInWithCodeMutation = () => {
  return useMutation<useSignInWithCodeMutationType>(useSignInCodeGQL as GraphQLTaggedNode)
}
export default useSignInWithCodeMutation
