import { useReducer } from 'react'

export enum Action {
  Reset,
  SetEmail,
  SetInvalidOTP,
  SetSuccess,
  SetError,
}

export enum SigninStep {
  Email,
  Code,
  Success,
}

export interface State {
  currentPage?: SigninStep
  email?: string
  code?: string
  error?: string
  invalidOTP?: boolean
}

export type Dispatch = React.Dispatch<{
  type: Action
  payload?: State | undefined
}>

export type ReducerAction = {
  type: Action
  payload?: State
}

export type SignInReducer = {
  state: State
  dispatch: Dispatch
}

const initialState: State = {
  currentPage: SigninStep.Email,
}

const reducerFn = (state: State, action: ReducerAction): State => {
  const { payload } = action

  switch (action.type) {
    case Action.Reset: {
      return { ...initialState }
    }

    case Action.SetEmail: {
      const email = payload?.email
      if (!email) return { ...state, error: 'Email must be provided' }

      return { ...state, email, currentPage: SigninStep.Code }
    }

    case Action.SetSuccess: {
      return { ...state, currentPage: SigninStep.Success }
    }

    case Action.SetError: {
      return { ...state, error: payload?.error }
    }

    case Action.SetInvalidOTP: {
      return {
        ...state,
        invalidOTP: true,
      }
    }

    default: {
      throw new Error(`Invalid state: ${action.type}`)
    }
  }
}

const useSignInReducer = (): SignInReducer => {
  const [state, dispatch] = useReducer(reducerFn, initialState)
  return { state, dispatch }
}

export default useSignInReducer
